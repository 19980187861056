import { withTitle } from '@gik/core/hocs/withTitle';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { PageSection } from '@gik/ui/gik/PageSection';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function NotFoundPage(): React.ReactElement {
  const bem = useBemCN('not-found-page');
  const { t } = useTranslation();

  return withTitle(
    'Not found',
    <PageSection {...bem()}>
      <hgroup>
        <h1>HTTP ERROR 404</h1>
        <h2>NOT FOUND</h2>
      </hgroup>
      <p {...bem('description')}>
        {`Sorry, we couldnt find what you were looking for. Sad face :(.\nMake a selection in the navigation above or the
        footer on the bottom.`}
      </p>
      <br />
      {/*<SearchButton />*/
      /* TODO */}
    </PageSection>
  );
}

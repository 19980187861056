import type { PageLayoutProps } from '@/components/layout/PageLayout';
import { PageLayout } from '@/components/layout/PageLayout';
import { NotFoundPage } from '@gik/core/pages/NotFound/NotFoundPage';
import React from 'react';

export default function NotFoundPageComp() {
  return <NotFoundPage />;
}

NotFoundPageComp.getLayout = function getLayout(page: React.ReactElement, layoutProps: PageLayoutProps) {
  return (
    <PageLayout {...layoutProps} key={layoutProps.key}>
      {page}
    </PageLayout>
  );
};
